<template>
  <div>
    <CLoading :loading="loading" />
    <div class="q-mb-sm" style="margin-top: -50px">
      <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
      <div class="horizontal-line q-mt-sm"></div>
    </div>
    <span class="text-h6 text-grey-6 text-bold q-mb-sm">フィルター</span>
    <ScenrioReportConditionSelector
      :stories="stories"
      :users="users"
      @update:queryParams="updateQueryParams"
    ></ScenrioReportConditionSelector>
    <div class="row q-col-gutter-md q-mb-md">
      <div class="col">
        <div class="row q-col-gutter-md">
          <div class="col-12">
            <div class="header-text">
              <span>シナリオレポート</span>
              <div class="header-horizontal-line"></div>
            </div>
          </div>
        </div>
        <q-table
          bordered
          flat
          dense
          separator="cell"
          v-if="qaReports"
          :rows="qaReports"
          :wrap-cells="true"
          ref="tableRef"
          :no-data-label="$t('messages.no_data_available')"
          class="audience-user text-grey-8"
          virtual-scroll
        >
          <template v-slot:header="props">
            <q-tr>
              <q-th :props="props" key="created_at" style="width: 160px"
                ><span class="text-bold">{{ $t('label.users.created_at') }}</span></q-th
              >
              <q-th :props="props" key="trigger_type" style="width: 120px"
                ><span class="text-bold">{{ $t('label.story.trigger_type_name') }}</span></q-th
              >
              <q-th :props="props" key="story_name" style="width: 300px"
                ><span class="text-bold">ストーリー名</span></q-th
              >
              <q-th :props="props" key="campaign_name" style="width: 300px"
                ><span class="text-bold">シナリオ名</span></q-th
              >
              <q-th :props="props" key="resource_name" style="width: 200px"><span class="text-bold">Q/A名</span></q-th>
              <q-th :props="props" key="resource_id_count" style="width: 200px"
                ><span class="text-bold">Q/A送信数</span></q-th
              >
              <q-th :props="props" key="answer_id_count" style="width: 200px"
                ><span class="text-bold">Q/A回答数</span></q-th
              >
              <q-th :props="props" key="goal_count" style="width: 200px"><span class="text-bold">ゴール数</span></q-th>
              <q-th :props="props" key="conversion_count" style="width: 300px"
                ><span class="text-bold">コンバージョン数</span></q-th
              >
              <q-th style="width: 330px"></q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="created_at" :props="props"> {{ props.row.created_at }}</q-td>
              <q-td key="trigger_type" :props="props">
                <q-chip :class="'story-type-' + props.row.trigger_type">
                  {{ $t('label.story.' + props.row.trigger_type + '_trigger') }}
                </q-chip>
              </q-td>
              <q-td key="story_name" :props="props">{{ props.row.story_name }}</q-td>
              <q-td key="campaign_name" :props="props">{{ props.row.campaign_name }}</q-td>
              <q-td key="resource_name" :props="props">{{ props.row.resource_name }}</q-td>
              <q-td key="resource_id_count" :props="props">{{ props.row.resource_id_count }}</q-td>
              <q-td key="answer_id_count" :props="props">{{ props.row.answer_id_count }}</q-td>
              <q-td key="goal_count" :props="props">{{ props.row.goal_count }}</q-td>
              <q-td key="conversion_count" :props="props">{{ props.row.conversion_count }}</q-td>
              <q-td>
                <div class="q-gutter-sm flex justify-end">
                  <q-btn
                    size="xs"
                    class="btn-cancel btn-open"
                    outline
                    label="シナリオ"
                    @click="onEditStory(props.row)"
                  />
                  <q-btn
                    size="xs"
                    class="btn-cancel btn-open"
                    outline
                    label="期間合計"
                    @click="onOpenGraphModal(props.row)"
                  />
                  <q-btn
                    size="xs"
                    class="btn-open"
                    :class="isSelectedRow(props.row)"
                    outline
                    label="グラフ表示"
                    @click="onDetailQAs(props.row)"
                  />
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-12">
        <div class="header-text">
          <span>Q/A回答詳細</span>
          <div class="header-horizontal-line"></div>
        </div>
        <div class="q-mb-md header-sub-text">シナリオレポートで選択したQ/Aの詳細を表示しています。</div>
      </div>
    </div>

    <div class="row q-col-gutter-md">
      <div class="col-8">
        <span class="header-sub-text-700 q-mb-sm">回答単位レポート</span>
        <q-table
          bordered
          flat
          dense
          separator="cell"
          v-if="answerReports.length > 0"
          :rows="answerReports"
          :wrap-cells="true"
          ref="answerReportsRef"
          :no-data-label="$t('messages.no_data_available')"
          class="audience-user text-grey-8"
          virtual-scroll
          :key="componentKeyAnswers"
        >
          <template v-slot:header="props">
            <q-tr>
              <q-th :props="props" key="answer_name" style="width: 200px"><span class="text-bold">回答</span></q-th>
              <q-th :props="props" key="answer_id_count" style="width: 150px"
                ><span class="text-bold">Q/A回答数</span></q-th
              >
              <q-th :props="props" key="answer_id_count" style="width: 150px"><span class="text-bold"></span></q-th>
              <q-th :props="props" key="goal_count" style="width: 150px"><span class="text-bold">ゴール数</span></q-th>
              <q-th :props="props" key="conversion_count" style="width: 170px"
                ><span class="text-bold">コンバージョン数</span></q-th
              >
              <q-th style="width: 85px">
                <q-select
                  class="chart-type"
                  size="xs"
                  outlined
                  dense
                  v-model="selectedChartType"
                  :options="chartTypeOptions"
                  emit-value
                  map-options
                  @update:model-value="onSelectedChartType"
                />
              </q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="answer_name" :props="props">{{ props.row.answer_name }}</q-td>
              <q-td key="answer_id_count" :props="props">
                {{ props.row.answer_id_count }}
              </q-td>
              <q-td key="answer_id_count" :props="props">
                <q-linear-progress size="20px" :value="getLinearProgress(props.row)" track-color="white" color="grey">
                </q-linear-progress>
              </q-td>
              <q-td key="goal_count" :props="props">{{ props.row.goal_count }}</q-td>
              <q-td key="conversion_count" :props="props">{{ props.row.conversion_count }}</q-td>
              <q-td
                ><q-btn
                  size="xs"
                  class="btn-open"
                  :class="isSelectedDetailRow(props.row)"
                  outline
                  label="ユーザー表示"
                  @click="onDetailAnswers(props.row)"
              /></q-td>
            </q-tr>
          </template>
        </q-table>
        <div
          v-if="answerReports.length == 0"
          class="cus-card"
          style="
            height: calc(100% - 32px);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          "
        >
          シナリオレポートから<br />
          Q/Aの「詳細表示」を選択してください。
        </div>
      </div>
      <div class="col">
        <span class="header-sub-text-700 q-mb-sm">ユーザー回答一覧</span>
        <q-table
          bordered
          flat
          dense
          separator="cell"
          v-if="detailAnswers.length > 0"
          :rows="detailAnswers"
          :wrap-cells="true"
          ref="detailAnswersRef"
          :no-data-label="$t('messages.no_data_available')"
          class="audience-user text-grey-8"
          virtual-scroll
          :key="componentKeyDetailAnswers"
        >
          <template v-slot:header="props">
            <q-tr>
              <q-th :props="props" key="created_at" style="width: 200px"
                ><span class="text-bold">{{ $t('label.users.created_at') }}</span></q-th
              >
              <q-th :props="props" key="display_name" style="width: 150px"
                ><span class="text-bold">ユーザー名</span></q-th
              >
              <q-th :props="props" key="resource_name" style="width: 150px"><span class="text-bold">Q/A名</span></q-th>
              <q-th :props="props" key="answer_name" style="width: 120px"><span class="text-bold">回答名</span></q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="created_at" :props="props"> {{ props.row.created_at }}</q-td>
              <q-td key="display_name" :props="props">{{ props.row.display_name }}</q-td>
              <q-td key="resource_name" :props="props">{{ selectedChartRow.resource_name }}</q-td>
              <q-td key="answer_name" :props="props">{{ props.row.answer_name }}</q-td>
            </q-tr>
          </template>
        </q-table>

        <div
          v-if="detailAnswers.length == 0"
          class="cus-card"
          style="
            height: calc(100% - 32px);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          "
        >
          シナリオレポートから<br />
          Q/Aの「詳細表示」を選択してください。
        </div>
      </div>
    </div>
    <OpenGraphModal
      v-if="modalVisible"
      :modalVisible="modalVisible"
      :queryParams="queryParams"
      :selectedChartRow="selectedQARow"
      :qaReports="qaReports"
      :isTestMode="isTestMode"
      @update:closeModal="onCloseModal"
    />
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_STORY, ACTION_APP_USER } from '@/store/actions'
import { Watch } from 'vue-property-decorator'

import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import ScenrioReportConditionSelector from '@/components/story/common/selectors/ScenrioReportConditionSelector.vue'
import OpenGraphModal from '@/components/story/common/OpenGraphModal.vue'

import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: {
    ScenrioReportConditionSelector,
    CLoading,
    TestModeSelector,

    OpenGraphModal,
  },
  directives: { maska },
})
export default class ScenarioReport extends mixins(BaseFormMixin) {
  modalVisible = false

  loading = false
  qaReports = []
  answerReports: {
    answer_id: string
    answer_name: string
    answer_id_count: number
    goal_count: number
    conversion_count: number
    items: []
  }[] = []

  detailAnswers = []
  componentKeyDetailAnswers = 0
  componentKeyAnswers = 0

  queryParams = {}

  isTestMode = false
  pagination = {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    // rowsNumber: 10,
  }

  selectedChartType = 'daily'
  chartTypeOptions = [
    {
      label: '日別',
      value: 'daily',
    },
    {
      label: '期間合計',
      value: 'period',
    },
  ]

  selectedDetailRow: {
    created_at?: string
    answer_id?: string
    answer_name?: string
  } = {}

  selectedChartRow: {
    created_at?: string
    resource_id?: string
    resource_id_count?: number
    answer_id_count?: number
    answers?: []
  } = {}

  selectedQARow = {}

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  get users() {
    return this.$store.getters.appUsers?.items
  }

  isSelectedRow(row) {
    if (
      this.selectedChartRow &&
      this.selectedChartRow.created_at === row.created_at &&
      this.selectedChartRow.resource_id === row.resource_id
    ) {
      return 'btn-save'
    }
    return 'btn-cancel'
  }

  isSelectedDetailRow(row) {
    if (
      this.selectedDetailRow &&
      this.selectedDetailRow.created_at === row.created_at &&
      this.selectedDetailRow.answer_id === row.answer_id &&
      this.selectedDetailRow.answer_name === row.answer_name
    ) {
      return 'btn-save'
    }
    return 'btn-cancel'
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }
    this.loading = true
    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {
        page: 1,
        per_page: 1000,
      },
    })

    this.loading = false
  }

  async updateQueryParams(params) {
    console.log(params)
    this.queryParams = params
    this.loadData()
  }

  resetData() {
    this.qaReports = []
    this.answerReports = []
    this.detailAnswers = []
    this.selectedChartRow = {}
    this.selectedChartType = 'daily'
    this.selectedDetailRow = {}
    this.selectedQARow = {}
  }

  @Watch('isTestMode')
  async loadData() {
    this.loading = true
    this.resetData()
    const returnedData = await this.$store.dispatch(ACTION_STORY.REPORT, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: this.queryParams,
    })

    if (returnedData) {
      // eslint-disable-next-line
      this.qaReports = returnedData['qa_reports']
    }

    this.loading = false
  }

  getLinearProgress(data) {
    let v = 0
    if (this.selectedChartRow && this.selectedChartRow.answer_id_count && this.selectedChartRow.answer_id_count !== 0) {
      v = data.answer_id_count / this.selectedChartRow.answer_id_count
    }
    return v
  }

  onEditStory(data) {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      story_id: data.story_id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onSelectedChartType(val) {
    console.log(val, 'onSelectedChartType')
    this.answerReports = []
    if (val === 'daily') {
      if (this.selectedChartRow) {
        this.answerReports = this.selectedChartRow.answers ?? []
      } else {
        this.option.series = []
      }
    } else {
      // Loop through each qaReport
      for (const row of this.qaReports) {
        // eslint-disable-next-line
        if (row['resource_id'] === this.selectedChartRow.resource_id) {
          let rowAnswers: {
            answer_id: string
            answer_name: string
            answer_id_count: number
            goal_count: number
            conversion_count: number
            items: []
          }[]
          // eslint-disable-next-line
          rowAnswers = row['answers']
          console.log(rowAnswers, " row['answers']")

          for (const answer of rowAnswers) {
            const existingAnswer = this.answerReports.find((a) => a.answer_id === answer.answer_id)
            if (existingAnswer) {
              existingAnswer.answer_id_count += answer.answer_id_count
              existingAnswer.goal_count += answer.goal_count
              existingAnswer.conversion_count += answer.conversion_count
              existingAnswer.items.push(...cloneDeep(answer.items))
            } else {
              this.answerReports.push(cloneDeep(answer))
            }
          }
        }
      }
    }

    this.componentKeyAnswers++
  }

  onDetailQAs(data) {
    this.selectedDetailRow = {}
    this.selectedChartRow = data
    this.detailAnswers = []
    this.selectedChartType = 'daily'
    this.onSelectedChartType(this.selectedChartType)
  }

  onOpenGraphModal(data) {
    console.log(data, 'onOpenGraphModal')
    this.selectedQARow = data
    this.modalVisible = true
  }

  onCloseModal() {
    // Edit modal
    this.modalVisible = false
  }

  onEditScenario(data) {
    console.log(data, 'onEditScenario')
  }

  onDetailAnswers(data) {
    this.selectedDetailRow = data
    if (data) {
      this.detailAnswers = data.items
    } else {
      this.detailAnswers = []
    }
    this.componentKeyDetailAnswers++
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
.stat-item:not(:last-child) {
  border-radius: 0;
  border-right: 1px solid $separator-color;
}
.user-id-col {
  width: 200px;
}
.is-active-col {
  width: 100px;
}
.audience-user {
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.test-mode {
  margin-left: -8px !important;
}

.btn-open {
  padding: 2px 6px;
  :deep(span) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
  }
}
.cus-card {
  border-radius: 4px;
  vertical-align: top;
  background: #fff;
  min-height: 100px;
}
.selected-row {
  background: #3982c21a;
}

.header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #333333;
}

.header-text span {
  margin-right: 8px;
}

.header-sub-text {
  font-size: 15px;
  color: #8f969e;
  font-weight: 400;
}

.header-sub-text-700 {
  font-size: 15px;
  font-weight: 700;
  color: #8f969e;
}

.header-horizontal-line {
  flex-grow: 1;
  border-bottom: 1px solid #d0d2e0;
  margin-left: 8px;
}

.chart-type {
  font-size: 8px;
  min-width: 50px;
  height: 30px !important;
  :deep(div) {
    font-weight: unset;
    line-height: unset;
    font-size: unset;
    letter-spacing: unset;
    height: unset;
    min-height: unset !important;
    .q-field__control {
      min-height: unset;
    }
  }
}
</style>
