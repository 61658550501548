
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { IInitialCampaign, IPushCampaign } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class ScenrioReportConditionSelector extends Vue {
  @Prop({})
  stories!: []

  @Prop({})
  users!: []

  startDate = ''
  endDate = ''
  currentDateType = ''
  selectedStories = []
  currentTriggerType = ''
  selectedScenarios = []
  currentScenarioType = ''
  selectedUnit = 'answer_unit'
  selectedUsers = []
  scenarios: {
    value: string
    label: string
  }[] = []

  units = [
    {
      label: '回答単位',
      value: 'answer_unit',
    },
    {
      label: 'ユーザー単位',
      value: 'per_user',
    },
  ]

  get queryParams() {
    return {
      start_date: this.startDate,
      end_date: this.endDate,
      stories: this.selectedStories,
      scenarios: this.selectedScenarios,
      users: this.selectedUsers,
      unit: this.selectedUnit,
      trigger_type: this.currentTriggerType,
      scenario_type: this.currentScenarioType,
    }
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  async onSelectedStories(val) {
    this.scenarios = []
    this.selectedScenarios = []
    if (val) {
      for (const storyId of val) {
        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)
        for (const p of pushes) {
          this.scenarios.push({
            value: p._id ?? '',
            label: p.title ?? '',
          })
        }
        const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
        if (initial._id !== '') {
          this.scenarios.push({
            value: initial._id ?? '',
            label: initial.title ?? '',
          })
        }
      }
    }
  }

  onSelectedScenarios(val) {
    console.log(val, 'onSelectedScenarios')
  }

  changeTriggerType(type) {
    if (type === this.currentTriggerType) {
      this.currentTriggerType = ''
    } else {
      this.currentTriggerType = type
    }
  }

  changeScenarioType(type) {
    if (type === this.currentScenarioType) {
      this.currentScenarioType = ''
    } else {
      this.currentScenarioType = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    const today = new Date()
    const formattedToday = today.toLocaleDateString('en-ZA')

    switch (this.currentDateType) {
      case 'today': {
        this.startDate = formattedToday
        this.endDate = formattedToday
        break
      }
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        this.startDate = yesterday.toLocaleDateString('en-ZA')
        this.endDate = yesterday.toLocaleDateString('en-ZA')
        break
      }
      case 'thisWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - adjustedDayOfWeek + 1)
        const endOfWeek = new Date(today)
        endOfWeek.setDate(today.getDate() + (7 - adjustedDayOfWeek))
        this.startDate = startOfWeek.toLocaleDateString('en-ZA')
        this.endDate = endOfWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'lastWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek - 6)
        const endOfPreviousWeek = new Date(today)
        endOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek)
        this.startDate = startOfPreviousWeek.toLocaleDateString('en-ZA')
        this.endDate = endOfPreviousWeek.toLocaleDateString('en-ZA')
        break
      }
      case 'thisMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        this.startDate = startOfMonth.toLocaleDateString('en-ZA')
        this.endDate = endOfMonth.toLocaleDateString('en-ZA')
        break
      }
      case 'lastMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfPreviousMonth = new Date(year, month - 1, 1)
        const endOfPreviousMonth = new Date(year, month, 0)
        this.startDate = startOfPreviousMonth.toLocaleDateString('en-ZA')
        this.endDate = endOfPreviousMonth.toLocaleDateString('en-ZA')
        break
      }
      default: {
        this.startDate = ''
        this.endDate = ''
        break
      }
    }
  }

  @Watch('queryParams')
  onChangeQueryParams() {
    this.$emit('update:queryParams', this.queryParams)
  }

  created() {
    this.currentDateType = 'thisMonth'
  }
}
