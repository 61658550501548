
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_STORY, ACTION_APP_USER } from '@/store/actions'
import { Watch } from 'vue-property-decorator'

import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import ScenrioReportConditionSelector from '@/components/story/common/selectors/ScenrioReportConditionSelector.vue'
import OpenGraphModal from '@/components/story/common/OpenGraphModal.vue'

import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: {
    ScenrioReportConditionSelector,
    CLoading,
    TestModeSelector,

    OpenGraphModal,
  },
  directives: { maska },
})
export default class ScenarioReport extends mixins(BaseFormMixin) {
  modalVisible = false

  loading = false
  qaReports = []
  answerReports: {
    answer_id: string
    answer_name: string
    answer_id_count: number
    goal_count: number
    conversion_count: number
    items: []
  }[] = []

  detailAnswers = []
  componentKeyDetailAnswers = 0
  componentKeyAnswers = 0

  queryParams = {}

  isTestMode = false
  pagination = {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    // rowsNumber: 10,
  }

  selectedChartType = 'daily'
  chartTypeOptions = [
    {
      label: '日別',
      value: 'daily',
    },
    {
      label: '期間合計',
      value: 'period',
    },
  ]

  selectedDetailRow: {
    created_at?: string
    answer_id?: string
    answer_name?: string
  } = {}

  selectedChartRow: {
    created_at?: string
    resource_id?: string
    resource_id_count?: number
    answer_id_count?: number
    answers?: []
  } = {}

  selectedQARow = {}

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get stories() {
    return cloneDeep(this.$store.getters.stories) || []
  }

  get users() {
    return this.$store.getters.appUsers?.items
  }

  isSelectedRow(row) {
    if (
      this.selectedChartRow &&
      this.selectedChartRow.created_at === row.created_at &&
      this.selectedChartRow.resource_id === row.resource_id
    ) {
      return 'btn-save'
    }
    return 'btn-cancel'
  }

  isSelectedDetailRow(row) {
    if (
      this.selectedDetailRow &&
      this.selectedDetailRow.created_at === row.created_at &&
      this.selectedDetailRow.answer_id === row.answer_id &&
      this.selectedDetailRow.answer_name === row.answer_name
    ) {
      return 'btn-save'
    }
    return 'btn-cancel'
  }

  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }
    this.loading = true
    await this.$store.dispatch(ACTION_STORY.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.appId,
      is_test_mode: this.isTestMode,
      filter: {
        page: 1,
        per_page: 1000,
      },
    })

    this.loading = false
  }

  async updateQueryParams(params) {
    console.log(params)
    this.queryParams = params
    this.loadData()
  }

  resetData() {
    this.qaReports = []
    this.answerReports = []
    this.detailAnswers = []
    this.selectedChartRow = {}
    this.selectedChartType = 'daily'
    this.selectedDetailRow = {}
    this.selectedQARow = {}
  }

  @Watch('isTestMode')
  async loadData() {
    this.loading = true
    this.resetData()
    const returnedData = await this.$store.dispatch(ACTION_STORY.REPORT, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: this.queryParams,
    })

    if (returnedData) {
      // eslint-disable-next-line
      this.qaReports = returnedData['qa_reports']
    }

    this.loading = false
  }

  getLinearProgress(data) {
    let v = 0
    if (this.selectedChartRow && this.selectedChartRow.answer_id_count && this.selectedChartRow.answer_id_count !== 0) {
      v = data.answer_id_count / this.selectedChartRow.answer_id_count
    }
    return v
  }

  onEditStory(data) {
    this.goto('story_setting', {
      app_id: this.selectedAppId,
      story_id: data.story_id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onSelectedChartType(val) {
    console.log(val, 'onSelectedChartType')
    this.answerReports = []
    if (val === 'daily') {
      if (this.selectedChartRow) {
        this.answerReports = this.selectedChartRow.answers ?? []
      } else {
        this.option.series = []
      }
    } else {
      // Loop through each qaReport
      for (const row of this.qaReports) {
        // eslint-disable-next-line
        if (row['resource_id'] === this.selectedChartRow.resource_id) {
          let rowAnswers: {
            answer_id: string
            answer_name: string
            answer_id_count: number
            goal_count: number
            conversion_count: number
            items: []
          }[]
          // eslint-disable-next-line
          rowAnswers = row['answers']
          console.log(rowAnswers, " row['answers']")

          for (const answer of rowAnswers) {
            const existingAnswer = this.answerReports.find((a) => a.answer_id === answer.answer_id)
            if (existingAnswer) {
              existingAnswer.answer_id_count += answer.answer_id_count
              existingAnswer.goal_count += answer.goal_count
              existingAnswer.conversion_count += answer.conversion_count
              existingAnswer.items.push(...cloneDeep(answer.items))
            } else {
              this.answerReports.push(cloneDeep(answer))
            }
          }
        }
      }
    }

    this.componentKeyAnswers++
  }

  onDetailQAs(data) {
    this.selectedDetailRow = {}
    this.selectedChartRow = data
    this.detailAnswers = []
    this.selectedChartType = 'daily'
    this.onSelectedChartType(this.selectedChartType)
  }

  onOpenGraphModal(data) {
    console.log(data, 'onOpenGraphModal')
    this.selectedQARow = data
    this.modalVisible = true
  }

  onCloseModal() {
    // Edit modal
    this.modalVisible = false
  }

  onEditScenario(data) {
    console.log(data, 'onEditScenario')
  }

  onDetailAnswers(data) {
    this.selectedDetailRow = data
    if (data) {
      this.detailAnswers = data.items
    } else {
      this.detailAnswers = []
    }
    this.componentKeyDetailAnswers++
  }
}
