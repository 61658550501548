<template>
  <q-dialog v-model="visible">
    <q-card style="width: 800px; max-width: 90vw">
      <q-card-section class="q-pa-none">
        <div class="flex justify-between">
          <div class="text-h5 q-ml-md"></div>
          <q-btn flat round dense icon="close" @click="onCancel" />
        </div>
        <div class="q-pl-md q-pr-md q-pb-md">
          <div class="horizontal-line">
            <div class="row no-wrap items-center date-select">
              <q-input
                outlined
                dense
                label="開始日"
                v-model="queryParams.start_date"
                mask="date"
                :rules="['date']"
                input-class="smaller-input"
                disable
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="queryParams.start_date">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <span class="text-subtitle2 text-grey-8 q-px-sm">-</span>
              <q-input
                outlined
                dense
                label="終了日"
                v-model="queryParams.end_date"
                mask="date"
                :rules="['date']"
                input-class="smaller-input"
                disable
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="queryParams.end_date">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="row q-pt-md">
            <div class="col-3">トリガー</div>
            <div class="col-3 q-pl-md">Q/A送信数</div>
            <div class="col-6"></div>
          </div>
          <div class="row" style="height: 300px">
            <div class="col-3" style="border-right: 1px solid #d0d2e0">
              <div class="q-pa-md">
                <q-chip :class="'story-type-' + selectedChartRow.trigger_type">
                  {{ $t('label.story.' + selectedChartRow.trigger_type + '_trigger') }}
                </q-chip>
              </div>
              <div>ストーリー名</div>
              <div class="q-pa-md">{{ selectedChartRow.story_name }}</div>
              <div>シナリオ名</div>
              <div class="q-pa-md">{{ selectedChartRow.campaign_name }}</div>
              <div>Q/A名</div>
              <div class="q-pa-md">{{ selectedChartRow.resource_name }}</div>
            </div>
            <div class="col-3 q-pl-md" style="border-right: 1px solid #d0d2e0">
              <div class="q-pa-md" style="text-align: center">{{ selectedChartRow.resource_id_count }}</div>
              <div>Q/A回答数</div>
              <div class="q-pa-md" style="text-align: center">{{ selectedChartRow.answer_id_count }}</div>
              <div>ゴール数</div>
              <div class="q-pa-md" style="text-align: center">{{ selectedChartRow.goal_count }}</div>
              <div>コンバージョン数</div>
              <div class="q-pa-md" style="text-align: center">{{ selectedChartRow.conversion_count }}</div>
            </div>
            <div class="col-6">
              <v-chart class="chart" :option="option" autoresize :key="componentKeydetailQAs" />
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang='ts'>
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import VChart from 'vue-echarts'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components'

import { IChartData } from '@/utils/types'

echarts.use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

@Options({
  components: { VChart },
  emits: ['update:modelValue', 'updateStartSettings'],
})
export default class OpenGraphModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  selectedChartRow: {
    created_at?: string
    resource_id?: string
    resource_id_count?: number
    answer_id_count?: number
    answers?: []
  } = {}

  @Prop()
  queryParams = {}

  @Prop()
  qaReports = []

  @Prop({ default: false })
  isTestMode = false

  fiftyData: IChartData[] = []
  chartData: IChartData[] = []
  componentKeydetailQAs = 0

  option = {
    color: ['#fff', '#3982C2', '#F42B73', '#3CAB38', '#8F969E', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: [0, '30%'],
        selectedMode: 'single',
        label: {
          position: 'center',
          fontSize: 20,
        },
        labelLine: {
          show: false,
        },
        data: [{ value: 0, name: '' }],
      },
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          fontSize: 10,
          show: true,
          position: 'outer',
          formatter: '{b}\n{c}',
        },
        emphasis: {
          label: {
            show: true,
            // fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: true,
        },
        data: this.chartData,
      },
    ],
  }

  onCancel() {
    this.visible = false
  }

  created() {
    const resource_id_count = this.selectedChartRow.resource_id_count ?? 0
    const answer_id_count = this.selectedChartRow.answer_id_count ?? 0
    const answers: {
      id: string
      name: string
      value: number
    }[] = []

    // // Loop through each qaReport
    // for (const row of this.qaReports) {
    //   // eslint-disable-next-line
    //   if (row['resource_id'] === this.selectedChartRow.resource_id) {
    //     let rowAnswers: {
    //       answer_id: string
    //       answer_name: string
    //       answer_id_count: number
    //     }[]
    //     // eslint-disable-next-line
    //     rowAnswers = row['answers']

    //     for (const col of rowAnswers) {
    //       const existingAnswer = answers.find((a) => a.id === col.answer_id)
    //       if (existingAnswer) {
    //         existingAnswer.value += col.answer_id_count
    //       } else {
    //         answers.push({
    //           id: col.answer_id,
    //           name: col.answer_name,
    //           value: col.answer_id_count,
    //         })
    //       }
    //     }
    //   }
    // }

    const rowAnswers: {
      answer_id: string
      answer_name: string
      answer_id_count: number
    }[] = this.selectedChartRow.answers ?? []
    for (const col of rowAnswers) {
      const existingAnswer = answers.find((a) => a.id === col.answer_id)
      if (existingAnswer) {
        existingAnswer.value += col.answer_id_count
      } else {
        answers.push({
          id: col.answer_id,
          name: col.answer_name,
          value: col.answer_id_count,
        })
      }
    }

    if (answers.length > 0) {
      let rate = 0
      if (resource_id_count) {
        rate = (answer_id_count / resource_id_count) * 100
      }
      const name = `回答率 ${Math.round(rate)}%`
      this.option.series[0].data = [{ value: 0, name: name }]
      this.option.series[1].data = answers
    } else {
      this.option.series = []
    }
    this.componentKeydetailQAs++
  }
}
</script>

<style scoped lang='scss'>
</style>
